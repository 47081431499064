import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { If } from 'presentation/components';
import { createBrowserRouter } from 'react-router-dom';

import BlankLayout from '../components/Layouts/BlankLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { routes } from './routes';
const queryClient = new QueryClient();
const finalRoutes = routes.map((route) => {
  return {
    ...route,
    element: (
      <QueryClientProvider client={queryClient}>
        <If condition={route.layout === 'blank'}>
          <BlankLayout>{route.element}</BlankLayout>
        </If>
        <If condition={route.layout !== 'blank'}>
          <DefaultLayout>{route.element}</DefaultLayout>
        </If>
      </QueryClientProvider>
    ),
  };
});

const router = createBrowserRouter(finalRoutes);

export default router;
