const maskDate = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
const maskHour = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

export const mask = {
  date: maskDate,
  phone: (value: string) => {
    const onlyNumber = value.replace(/\D/g, '');
    if (onlyNumber.length <= 10) return ['(', /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

    return ['(', /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  },
  cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/],
  dateRange: [...maskDate, ' ', '-', ' ', ...maskDate],
  dateHour: [...maskDate, ' ', /[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/],
  hour: [...maskHour],
  realBrasileiro: (value: string) => {
    const onlyNumber = value.replace(/\D/g, '');
    if (onlyNumber.length <= 2) return ['R$', ' ', /[0-9]/];
    if (onlyNumber.length <= 5) return ['R$', ' ', /[0-9]/, /[0-9]/];
    if (onlyNumber.length <= 8) return ['R$', ' ', /[0-9]/, /[0-9]/, /[0-9]/];
  },
};
