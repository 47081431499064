function getProxyUrl() {
  const protocol = window.location.protocol;
  let proxy = import.meta.env.VITE_APP_PROXY_URL || 'http://localhost:7000';
  const protocols = ['http:', 'https:'];
  protocols.forEach((p) => {
    proxy = proxy.replace(p, `${protocol}`);
  });
  return proxy;
}

function getTenantHost() {
  return import.meta.env.VITE_APP_TENANT_HOST || window.location.host;
}

class Env {
  get PROXY_URL() {
    return getProxyUrl();
  }
  get DEVELOPMENT() {
    return import.meta.env.MODE == 'development';
  }
  get TIMBRADO() {
    return import.meta.env.VITE_APP_TIMBRADO as string;
  }
  get DEV_USER() {
    return import.meta.env.VITE_APP_DEV_USER || '';
  }
  get DEV_PASSWORD() {
    return import.meta.env.VITE_APP_DEV_PASSWORD || '';
  }
  get API_URL() {
    return import.meta.env.VITE_APP_API_URL || window.location.origin;
  }
  get TENANT_HOST() {
    return getTenantHost();
  }
  get X_API_KEY() {
    return import.meta.env.VITE_APP_X_API_KEY || '';
  }
}
const env = new Env();
export default env;
