import { InstitutionUnit } from 'domain/models/data';
import { GQLListResponse, InstitutionUnitQuery } from 'domain/models/graphql';
import { GetInstitutionUnitById, InstitutionUnitByIdParams, ListInstitutionUnit, ListInstitutionUnitParams, ListInstitutionUnitResponse } from 'domain/usecases';
import { executeQuery } from 'infra/graphql/client';
import { makeInstitutionUnitListQuery } from 'infra/graphql/queries';

export class GqlLisInstitutionUnit implements ListInstitutionUnit {
  async fetch(params: ListInstitutionUnitParams): Promise<GQLListResponse<InstitutionUnit>> {
    const { filter, include } = params;
    if (!filter.limit) {
      filter.limit = 10;
    }
    const query = makeInstitutionUnitListQuery({ include });
    const { listInstitutionUnit } = await executeQuery<ListInstitutionUnitResponse, InstitutionUnitQuery>(query, filter);
    return listInstitutionUnit;
  }
}

export class GqlGetInstitutionUnitById implements GetInstitutionUnitById {
  constructor(private readonly listInstitutionUnit: ListInstitutionUnit) {}
  async fetch({ id, include }: InstitutionUnitByIdParams) {
    const { results } = await this.listInstitutionUnit.fetch({
      filter: { id },
      include,
    });
    const [item] = results;
    return item;
  }
}
