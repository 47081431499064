import { InstitutionByIdParams, ListInstitutionUnitParams } from '@diegodfreire/sade-gql-client-js/dist';
import { useQuery } from '@tanstack/react-query';
import { makeGetInstitutionById, makeListInstitutionUnit } from 'main/factories/institution-unit';

export const useInstitutionUnits = (params?: Partial<ListInstitutionUnitParams>) => {
  const defaultParams: ListInstitutionUnitParams = {
    filter: {},
    include: { id: true, name: true },
  };
  const queryParam = {
    filter: { ...defaultParams.filter, ...params?.filter },
    include: { ...defaultParams.include, ...params?.include },
  } as any; // TODO: fix this any
  return useQuery({
    queryKey: useInstitutionUnits.queryKey(queryParam),
    queryFn: () => {
      const query = makeListInstitutionUnit();
      return query.fetch(queryParam);
    },
    staleTime: 1000 * 60 * 5,
  });
};

useInstitutionUnits.queryKey = (params: ListInstitutionUnitParams) => ['institution-units', params];

export const useInstitutionUnit = (id: string) => {
  console.log('useInstitutionUnit', id);
  return useQuery({
    queryKey: useInstitutionUnit.queryKey(id),
    queryFn: () => {
      const query = makeGetInstitutionById();
      return query.fetch({ id, include: { name: true, id: true } });
    },
    staleTime: Infinity,
  });
};

useInstitutionUnit.queryKey = (id: string) => ['institution-unit', id];
