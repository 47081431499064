import dayjs from 'dayjs';
import parser from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import tz from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(parser);
dayjs.extend(updateLocale);
dayjs.extend(isBetween);
dayjs.locale('pt-br');
dayjs.tz.setDefault('America/Sao_Paulo');

// @ts-ignore
dayjs.updateLocale('en', {
  months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
});
const dayjsAdapter: (date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string | boolean, strict?: boolean) => dayjs.Dayjs = dayjs;

const createDate = (date?: dayjs.ConfigType) => {
  return dayjs(date || new Date()).tz('America/Sao_Paulo');
};

export { createDate, dayjsAdapter };
export default dayjsAdapter;
