import { InstitutionUnit } from 'domain/models/data';
import { GraphqlQueryParams, InstitutionUnitQuery } from 'domain/models/graphql';

import { makeGraphqlPaginateQuery } from './gql-query-factory';

export type InstitutionUnitGqlParams = Omit<GraphqlQueryParams<InstitutionUnit, InstitutionUnitQuery>, 'queryName' | 'filterKeys'>;

export const makeInstitutionUnitListQuery = (params: InstitutionUnitGqlParams) => {
  return makeGraphqlPaginateQuery<InstitutionUnit, InstitutionUnitQuery>({
    queryName: 'listInstitutionUnit',
    filterKeys: {
      id: 'UUID',
    },
    ...params,
  });
};
