import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Index = lazy(() => import('../pages/Index'));
const Error = lazy(() => import('../components/Error'));
const Error404 = lazy(() => import('presentation/components/pages/Error404'));
const ErrorFallback = lazy(() => import('../components/ErrorBoundaryFallback'));
const RedirectUIPath = lazy(() => import('../pages/redirect-ui'));

// PATIENT
const ListPatient = lazy(() => import('../presentation/pages/patients/ListPatient'));
const ReportForm = lazy(() => import('../presentation/pages/attendance/calendar/report/ReportForm'));
const LoginPage = lazy(() => import('../presentation/pages/login/LoginPage'));
const NewPatient = lazy(() => import('../presentation/pages/patients/NewPatient'));
const EditPatient = lazy(() => import('../presentation/pages/patients/EditPatient'));
const MedicalRecord = lazy(() => import('../presentation/pages/patients/medical-record'));

// PROCEDURE
const ListProcedure = lazy(() => import('../presentation/pages/attendance/procedures/ListProcedure'));
const NewProcedure = lazy(() => import('../presentation/pages/attendance/procedures/NewProcedure'));
const EditProcedure = lazy(() => import('../presentation/pages/attendance/procedures/EditProcedure'));

// Calendar
const ListAttendance = lazy(() => import('../presentation/pages/attendance/calendar/ListAttendance'));
const NewAttendance = lazy(() => import('../presentation/pages/attendance/calendar/NewAttendance'));
const EditAttendance = lazy(() => import('../presentation/pages/attendance/calendar/EditAttendance'));

const ListAttendanceDone = lazy(() => import('../presentation/pages/attendance/done/ListAttendanceDone'));
const ListHealthPlan = lazy(() => import('../presentation/pages/health-plan/ListHealthPlan'));
const NewHealthPlan = lazy(() => import('../presentation/pages/health-plan/NewHealthPlan'));
const EditHealthPlan = lazy(() => import('../presentation/pages/health-plan/EditHealthPlan'));

// Profissionais
const ListProfessional = lazy(() => import('../presentation/pages/professional/ListProfessional'));
const NewProfessional = lazy(() => import('../presentation/pages/professional/NewProfessional'));
const EditProfessional = lazy(() => import('../presentation/pages/professional/EditProfessional'));
const BookingConfigCalendar = lazy(() => import('../presentation/pages/professional/booking-config/booking-config-calendar'));
const BookingConfigCreate = lazy(() => import('../presentation/pages/professional/booking-config/booking-config-create'));
const BookingConfigEdit = lazy(() => import('../presentation/pages/professional/booking-config/booking-config-date-edit'));

// CONFIGs
const ListTemplate = lazy(() => import('../presentation/pages/config/templates/list-template'));
const NewTemplate = lazy(() => import('../presentation/pages/config/templates/new-template'));
const EditTemplate = lazy(() => import('../presentation/pages/config/templates/edit-template'));

const EditEquipament = lazy(() => import('../presentation/pages/config/equipments/edit-equipment'));
const ListEquipament = lazy(() => import('../presentation/pages/config/equipments/list-equipment'));
const NewEquipament = lazy(() => import('../presentation/pages/config/equipments/new-equipment'));

const EditServer = lazy(() => import('../presentation/pages/config/server/edit-server'));
const ListServer = lazy(() => import('../presentation/pages/config/server/list-server'));
const NewServer = lazy(() => import('../presentation/pages/config/server/new-server'));

// User Profile
const UserSignature = lazy(() => import('../presentation/pages/perfil/signature/Signature'));
const FormResetPassword = lazy(() => import('presentation/pages/perfil/reset-password/ResetPassword'));

const PatientAccessResult = lazy(() => import('presentation/pages/patient-result'));

const AnalyticsPage = lazy(() => import('presentation/pages/analytics/analytics-page'));
const ImageWindowView = lazy(() => import('../presentation/pages/attendance/calendar/report/ImageWindowView'));
const SignResult = lazy(() => import('presentation/pages/attendance/calendar/report/Sign'));

type RouteType = RouteObject & { layout?: string };

const routes: RouteType[] = [
  // dashboard
  {
    path: '/',
    element: <ListAttendance />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    layout: 'blank',
    errorElement: <ErrorFallback />,
  },
  // table test
  {
    path: '/pacientes',
    element: <ListPatient />,
    // errorElement: <ErrorFallback />,
  },
  {
    path: '/pacientes/add',
    element: <NewPatient />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/pacientes/edit/:id',
    element: <EditPatient />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/pacientes/:id/prontuario',
    element: <MedicalRecord />,
    errorElement: <ErrorFallback />,
  },

  // atendimento
  {
    path: '/atendimento/procedimentos',
    element: <ListProcedure />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/procedimentos/add',
    element: <NewProcedure />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/procedimentos/edit/:id',
    element: <EditProcedure />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/agenda',
    element: <ListAttendance />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento',
    element: <ListAttendance />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/agenda/add',
    element: <NewAttendance />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/agenda/edit/:id',
    element: <EditAttendance />,
    errorElement: <ErrorFallback />,
  },
  // report
  {
    path: '/atendimento/atender/:id',
    element: <ReportForm />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/:id/assinar',
    element: <SignResult />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/atendimento/images-view/:id',
    element: <ImageWindowView />,
    errorElement: <ErrorFallback />,
    layout: 'blank',
  },
  //
  {
    path: '/atendimento/concluido',
    element: <ListAttendanceDone />,
    errorElement: <ErrorFallback />,
  },

  {
    path: '/convenios',
    element: <ListHealthPlan />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/convenios/add',
    element: <NewHealthPlan />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/convenios/edit/:id',
    element: <EditHealthPlan />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais',
    element: <ListProfessional />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais/add',
    element: <NewProfessional />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais/:id/edit',
    element: <EditProfessional />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais/:id/reservas',
    element: <BookingConfigCalendar />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais/:id/reservas/configurar',
    element: <BookingConfigCreate />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/profissionais/:id/reservas/configurar/:dateId',
    element: <BookingConfigEdit />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/user/signature',
    element: <UserSignature />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/user/reset-password',
    element: <FormResetPassword />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/templates',
    element: <ListTemplate />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/templates/add',
    element: <NewTemplate />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/templates/edit/:id',
    element: <EditTemplate />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/equipamentos',
    element: <ListEquipament />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/equipment/add',
    element: <NewEquipament />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/equipment/edit/:id',
    element: <EditEquipament />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/servidores',
    element: <ListServer />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/server/add',
    element: <NewServer />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/config/server/edit/:id',
    element: <EditServer />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/analitico',
    element: <AnalyticsPage />,
    errorElement: <ErrorFallback />,
  },
  {
    path: '/resultado',
    element: <PatientAccessResult />,
    errorElement: <ErrorFallback />,
    layout: 'blank',
  },
  {
    path: '/ui*',
    element: <RedirectUIPath />,
    layout: 'blank',
  },

  {
    path: '*',
    element: <Error404 />,
    errorElement: <ErrorFallback />,
    layout: 'blank',
  },
];

export { routes };
